// Import the necessary components 
import React, { useState, useEffect } from "react";
import { Table, Button, Pagination } from 'react-bootstrap';
// Import the auth hook  
import { useAuth } from "../../../../context/AuthContext";
// Import the date-fns library 
import { format } from 'date-fns'; // To properly format the date on the table 
// Import the getAllEmployees function  
import employeeService from "../../../../services/employee.service";
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import './employeeList.css';
import AdminMenu from "../AdminMenu/AdminMenu";

// Create the EmployeesList component 
const EmployeesList = () => {
  // State to store employees data
  const [employees, setEmployees] = useState([]);
  // State to show error message
  const [apiError, setApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(null);
  const [employeeIdToDelete, setEmployeeIdToDelete] = useState(null);
  // States for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(7);

  // To get the logged-in employee token
  const { employee } = useAuth();
  const token = employee ? employee.employee_token : null;

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const res = await employeeService.getAllEmployees(token);
        if (!res.ok) {
          throw new Error(res.status);
        }
        const data = await res.json();
        if (data.data.length !== 0) {
          setEmployees(data.data);
        }
      } catch (error) {
        setApiError(true);
        if (error.message === '401') {
          setApiErrorMessage("Please login again.");
        } else if (error.message === '403') {
          setApiErrorMessage("You are not authorized to view this page.");
        } else {
          setApiErrorMessage("Please try again later.");
        }
      }
    };

    fetchEmployees();
  }, [token]);

  const handleDeleteEmployee = async (employeeId) => {
    try {
      setEmployeeIdToDelete(employeeId);
      const response = await employeeService.deleteEmployee(employeeId, token);
      if (response.ok) {
        setEmployees((prevEmployees) =>
          prevEmployees.filter((employee) => employee.employee_id !== employeeId)
        );
      } else {
        throw new Error("Error deleting employee.");
      }
    } catch (error) {
      setApiError(true);
      setApiErrorMessage("Error deleting employee. Please try again later.");
    } finally {
      setEmployeeIdToDelete(null);
    }
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = employees.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container-fluid admin-pages">
      <div className="row">
        <div className="col-md-3 admin-left-side">
          <AdminMenu />
        </div>
        <div className="col-md-9 admin-right-side">
          {apiError ? (
            <section className="contact-section">
              <div className="auto-container">
                <div className="contact-title">
                  <h2>{apiErrorMessage}</h2>
                </div>
              </div>
            </section>
          ) : (
            <section className="contact-section">
              <div className="auto-container">
                <div className="contact-title">
                  <h2>Employees</h2>
                </div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Active</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Position</th> {/* New Position Column */}
                      <th>Specialization</th> {/* New Specialization Column */}
                      <th>Added Date</th>
                      <th>Role</th>
                      <th>Photo</th>
                      <th>Edit/Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((employee) => (
                      <tr key={employee.employee_id}>
                        <td>{employee.employee_active_status ? "Yes" : "No"}</td>
                        <td>{employee.employee_first_name}</td>
                        <td>{employee.employee_last_name}</td>
                        <td>{employee.employee_email}</td>
                        <td>{employee.employee_phone}</td>
                        <td>{employee.position || 'N/A'}</td> {/* Display Position */}
                        <td>{employee.specialization || 'N/A'}</td> {/* Display Specialization */}
                        <td>{format(new Date(employee.created_at), 'MM/dd/yyyy')}</td>
                        <td>{employee.company_role_name}</td>
                        <td>
                          {employee.employee_image ? (
                            <img src={employee.employee_image} alt={`${employee.employee_first_name}`} width="50" />
                          ) : (
                            'No Image'
                          )}
                        </td>
                        <td>
                          <div className="edit-delete-icons">
                            <FaEdit />
                            | {employeeIdToDelete === employee.employee_id ? (
                              <span>Deleting...</span>
                            ) : (
                              <FaTrashAlt
                                onClick={() => handleDeleteEmployee(employee.employee_id)}
                                role="button"
                                tabIndex="0"
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {/* Pagination component */}
                <Pagination>
                  {Array.from({ length: Math.ceil(employees.length / itemsPerPage) }, (_, i) => i + 1).map((pageNumber) => (
                    <Pagination.Item
                      key={pageNumber}
                      active={pageNumber === currentPage}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </Pagination.Item>
                  ))}
                </Pagination>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

// Export the EmployeesList component 
export default EmployeesList;