import React from 'react';
// import './CTASection.css'; // Optional: Import a CSS file if needed

const CTASection = () => {
  return (
    <section className="cta-section">
      <div className="auto-container">
        <div className="wrapper-box">
          <div className="left-column">
            <h3>Schedule Your Appointment Today</h3>
            <div className="text">Explore Your Future @ Admas University!
            Meet with an Admissions Advisor to Learn About Our Programs, Campus Life, and Scholarship Opportunities.</div>
          </div>
          <div className="right-column">
          <span className="flaticon-phone social-icon" style={{color:'white', fontSize:'2rem'}}>  </span>
            <div className="phone">0115-50-88-08/10</div>
            <div className="btn">
              <a href="http://eepurl.com/i8Civg" className="theme-btn btn-style-one" target='_blank'>
                <span> Register Now</span>
                <i className="flaticon-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTASection;