import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import createNews from '../../../../services/news.service';
import AdminMenu from '../../../components/Admin/AdminMenu/AdminMenu';

const NewsForm = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('');
  const [image, setImage] = useState(null);
  const [loggedInEmployeeToken, setLoggedInEmployeeToken] = useState('your_token_here');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = {
      title,
      content,
      author
    };
  
    try {
      await createNews.createNews(formData, image, loggedInEmployeeToken);
      // Reset the form fields
      setTitle('');
      setContent('');
      setAuthor('');
      setImage(null);
    } catch (error) {
      console.error('Error creating news:', error);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="container-fluid admin-pages">
      <div className="row">
        <div className="col-md-3 admin-left-side">
          <AdminMenu />
        </div>
        <div className="col-md-9 admin-right-side">
          <form onSubmit={handleSubmit} className='addnews'>
            <h1>Add News</h1>
            <div>
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="content">Content:</label>
              <ReactQuill
                id="content"
                value={content}
                onChange={setContent}
                required
              />
            </div>
            <div>
              <label htmlFor="author">Author:</label>
              <input
                type="text"
                id="author"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="image">Image:</label>
              <input
                type="file"
                id="image"
                onChange={handleImageChange}
                required
              />
            </div>
            <button type="submit">Create News</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewsForm;