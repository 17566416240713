import React from 'react';
import './HeaderTop.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube, faTelegramPlane } from '@fortawesome/free-brands-svg-icons';

function HeaderTop() {
  return (
    <div className="header-top">
      <div className="auto-container">
        <div className="inner-container">
          <div className="left-column">
            <div className="text">Elevate Your Potential: Discover, Innovate, Transform <span className=" social-icon">@</span>Admas University</div>
          </div>
          <div className='text '>
          
                    <span className="flaticon-phone social-icon">  </span>
                    <span className=" social-icon">Call Us :</span>  0115-50-88-08/10
          </div>
          <div className="left-column">
            <a href="https://web.facebook.com/AdmasUni?_rdc=1&_rdr#" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://youtu.be/LX27kp0ykDs" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a href="https://t.me/AdmasAA" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FontAwesomeIcon icon={faTelegramPlane} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HeaderTop