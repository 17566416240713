import React from 'react'
import campus from "../../assets/images/resource/project-10.jpg" ;
import office from '../../assets/images/background/office.jpg';
import photo from "../../assets/images/president.png";
import mekanisa2 from '../../assets/images/icons/mekanisa2.jpg';
import Dean from '../../assets/images/icons/teklay.jpg'
import MekeleStudent from  '../../assets/images/icons/mekelestudent.jpg'
const PresidentOffice = () => {
  return (
    <div>
        
  
    <div className="banner-slide">
          <img src={office} alt="Banner 2" className="banner-image" />
          <div className="banner-content">
          <h1>Transforming Lives</h1>
            {/* <div className="text"> */}
              We believe in providing top quality workmanship and are <br />
              so confident in our level of service that we back it up <br /> with a good quality.
            {/* </div> */}
          </div>
        </div>

    <section class="projects-details">
      <h3 style={{textAlign:'center', marginBottom:'30px'}}>Office of the President</h3>
        <div class="auto-container">
            <div class="text-block">
                <div class="row">
                    <div class="col-lg-10">
                        <h4>   Dear Members of the Admas University Community,</h4>
                        <div class="text">
                     
                       <p> Welcome to the Office of the President! Here at Admas University, 
                        we are committed to fostering an environment of academic excellence, 
                        innovation, and community engagement. 
                        As we navigate the challenges and opportunities of higher education, 
                        I invite you to explore our initiatives and join us in our mission to empower students and enrich our community. 
                        </p>
                                                   
                        </div>
                    </div>
                   
                </div>
               </div>
            <div class="text-block">
                <div class="row">
                    <div class="col-lg-6">
                    <div className="sec-title style-two">
              <h4>Meet the President</h4>
            </div>
            <div>
              <p>
              Short biography highlighting the President’s background, academic qualifications, and leadership experience. Mention any significant achievements and contributions to the university and community.
              </p>
            </div>
        
                    </div>
                    <div class="col-lg-3">
                        <div class="image"><img src={photo} alt=""/></div>
                        <p className='designation' >Molla Tsegay (PhD)</p>

                    </div>
                </div>
            </div>
           <hr/>
            <div class="text-block-two">
                <div class="row">
                    <div class="col-lg-12">
                        <h4>Strategic Initiatives</h4>
                        <div class="text">
                        The Office of the President is dedicated to several key initiatives that enhance our university's impact:                        </div>
                     <ul>
                      <li><strong> Academic Excellence:</strong> Implementation of innovative programs that align with industry needs and student interests.</li>
                      <li><strong>Research and Development:</strong> Supporting groundbreaking research that addresses local and global challenges.</li>
                      <li><strong>Community Engagement:</strong> Strengthening ties with local organizations to promote community service and outreach.</li>
                     </ul>
                    </div>
                 
                </div>
            </div>

            <hr/>
            <div class="text-block-two">
                <div class="row">
                    <div class="col-lg-10">
                        <h4>Accomplishments and Milestones</h4>
                        <div class="text">
                        Under the leadership of the President, Admas University has achieved:                <ul>
                      <li> Launching [specific programs or initiatives].</li>
                      <li> Receiving [awards or recognitions].</li>
                      <li> Establishing partnerships with [local and international institutions].</li>
                     </ul>
                    </div>
                 
                </div>
            </div>
        </div>

        <hr/>
            <div class="text-block-two">
                <div class="row">
                    <div class="col-lg-10">
                        <h4>Community Engagement</h4>
                        <div class="text">
                        The Office of the President believes in the power of community. Our initiatives include:
                      <ul>
                      <li>Town Hall Meetings: Regular sessions where students, faculty, and staff can voice their opinions and suggestions.</li>
                      <li>Service Projects: Collaborating with local organizations to address community needs through volunteer opportunities.</li>
                      </ul>
                    </div>
                 
                </div>
            </div>
        </div>
        </div>


        <hr/>
            <div class="text-block-two">
                <div class="row">
                    <div class="col-lg-10">
                        <h4>Policies and Governance</h4>
                        <div class="text">
                        The Office of the President is committed to transparent governance. Here, you can find information about:  
                                            <ul>
                      <li>University Policies: Access to important documents and guidelines that shape our academic environment.</li>
                      <li>Governance Structure: An overview of how decisions are made within the university.</li>
                      </ul>
                    </div>
                 
                </div>
            </div>
        </div>
      

        <hr/>
            <div class="text-block-two">
                <div class="row">
                    <div class="col-lg-10">
                        <h4>Contact Us</h4>
                        <div class="text">
                        For more information or inquiries, please reach out to the Office of the President: 
                        Email: [president@admasuniversity.edu.et]
Phone: [+251 123 456 789]
Office Location: [Building Name, Room Number, Admas University]
                    </div>
                 
                </div>
            </div>
        </div>
      


    </section>
    </div>
  )
}

export default PresidentOffice