import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import image1 from '../../../assets/images/main-slider/image-1.jpg';
import image2 from '../../../assets/images/main-slider/image-2.jpg';
import image3 from '../../../assets/images/main-slider/image-3.jpg';

import './Banner.css'
const BannerSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: (
      <div className="banner-slider-control banner-slider-button-prev">
        <span>
          <i className="far fa-angle-left"></i>
        </span>
      </div>
    ),
    nextArrow: (
      <div className="banner-slider-control banner-slider-button-next">
        <span>
          <i className="far fa-angle-right"></i>
        </span>
      </div>
    ),
  };

  return (
    <section className="banner-section">
      <Slider {...settings}>
        <div className="banner-slide">
          <img src={image1} alt="Banner 1" className="banner-image" />
          <div className="banner-content">
            <h1> Admas University – Ethiopia’s Leading Private University</h1>          
           Empowering Minds, Transforming Lives
           Committed to quality education &  & community impact, Admas University nurtures future leaders through innovation, excellence, and practical learning. Join us in shaping a brighter future!
          </div>
        </div>
        <div className="banner-slide">
          <img src={image2} alt="Banner 2" className="banner-image" />
          <div className="banner-content">
          <h1>Ensuring Student Success Through High-Quality Instruction and Support</h1>
          At our university, <br/>
          we believe in delivering top-notch instruction and support to ensure student success.       
          </div>
        </div>
        <div className="banner-slide">
          <img src={image3} alt="Banner 3" className="banner-image" />
          <div className="banner-content">
          <h1>Empowering Students Through a Dedicated and Accomplished Faculty</h1>          
            Our faculty are highly qualified <br/>
            and dedicated to helping students reach their full potential.
    
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default BannerSlider;