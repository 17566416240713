import React from 'react';

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us">
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-6">
            <div className="sec-title style-two">
            <h3 style={{ textAlign: 'center', marginTop: '15px' }}>Why Choose Us</h3>
              <div className="text" >
              Choosing our university will position you for unmatched academic and professional success. Our renowned faculty, cutting-edge facilities, and vibrant campus life create an exceptional student experience. We offer transparent admissions, generous financial aid, and a powerful alumni network to support your goals. Invest in a degree that will elevate your future and open doors to limitless opportunities.             </div>
            </div>
            <div className="icon-box">
              <div className="icon"><span className="flaticon-book"></span></div>
              <h4>Academic Programs & Reputation</h4>
            </div>
            <div className="icon-box">
              <div className="icon"><span className="flaticon-book"></span></div>
              <h4>Campus Life & Student Experience</h4>
            </div>
            <div className="icon-box">
              <div className="icon"><span className="flaticon-book"></span></div>
              <h4>Faculty and Research Opportunities</h4>
            </div>
            <div className="icon-box">
              <div className="icon"><span className="flaticon-book"></span></div>
              <h4>Location and Facilities</h4>
            </div>
            <div className="icon-box">
              <div className="icon"><span className="flaticon-book"></span></div>
              <h4>Alumni Network & Career Outcomes</h4>
            </div>


            
          </div>
          
          <div className="col-lg-6">
            <div className="sec-title style-two">
            <h3 style={{ textAlign: 'center', marginTop: '15px' }}>Accredited Programs</h3>
             
            </div>
            <div className="row">
              <div className="col-md-5">
                <div className="image"><img src="assets/images/resource/image-4.jpg" alt="" /></div>
              </div>
              <div className="col-md-7">
                <div className='programs' >Master's Degree</div>
                <ul className="list">
                  <>

                    <ul className="sub-list ">
                      <li >Accounting & Finance</li>
                      <li>Computer Science</li>
                      <li>Master of Business Administration (MBA)</li>
                      <li>Project Management</li>
                    </ul>
                  </>
                  <div className='programs' >Bachler's Degree</div>
                  <>
                    <ul className="sub-list">
                      <li>Accounting & Finance</li>
                      <li>Business Management</li>
                      <li>Marketing Managemnt</li>
                      <li>Computer Science</li>
                      <li>Educational Planning & Management (EDPM)</li>
                      <li>Economics</li>
                      <li>Agricultural Economics</li>
                      <li>Rural Development & Agricultural Extension</li>
                    </ul>
                  </>
                  <div className='programs' >TVET</div>
                  <>
                    <ul className="sub-list">
                    <li>Accounting & Finance Level 2-4</li>
<li>Marketing & Sales Management Level 2-4</li>
<li>Secretarial Science & Office Administration Level 2-4</li>
<li>Human Resources Management Level 2-4</li>
<li>Hardware & Networking Servicing Level 1-4</li>
<li>Web Development & Database Administration Level 1-4</li>
<li>Purchasing & Supplies Management Level 2-4</li>
<li>Food & Beverage Service Level 1-4</li>
<li>Housekeeping & Laundry Service Level 1-4</li>
<li>Tour Operation Level 2-4</li>
<li>Tour Guiding Level 2-4</li>
                    
                    </ul>


                  </>
             
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;