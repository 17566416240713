// Import from the env 
const api_url = process.env.REACT_APP_API_URL;

const createNews = async (formData, image, loggedInEmployeeToken) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'x-access-token': loggedInEmployeeToken
    },
    body: new FormData()
  };

  requestOptions.body.append('title', formData.title);
  requestOptions.body.append('content', formData.content);
  requestOptions.body.append('author', formData.author);
  requestOptions.body.append('image', image);

  const response = await fetch(`${api_url}/api/employee/news`, requestOptions);
  return response;
};


// A function to send get request to get all news
const getAllNews = async (token) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    }
  };
  const response = await fetch(`${api_url}/api/employee/news`, requestOptions);
  if (!response.ok) {
    throw new Error(`HTTP error ${response.status}`);
  }
console.log(response)
  return response.json();
};



// A function to send delete request to delete an employee
const deleteNews = async (newsId, token) => {
  console.log(newsId)
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    }
  };
  const response = await fetch(`${api_url}/api/employee/news/${newsId}`, requestOptions);
  return response;
}

const updateNews = async (newsId, updatedNews, image, token) => {
  try {
    // Validate the updatedNews object
    if (!updatedNews || typeof updatedNews !== 'object' || !updatedNews.title || !updatedNews.content || !updatedNews.author) {
      throw new Error('Title, content, and author fields are required');
    }

    const formData = new FormData();
    formData.append('news', JSON.stringify(updatedNews));

    if (image) {
      formData.append('image', image);
    }

    const requestOptions = {
      method: 'PUT',
      headers: {
        'x-access-token': token
      },
      body: formData
    };

    console.log(requestOptions);

    const response = await fetch(`${api_url}/api/employee/news/${newsId}`, requestOptions);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`HTTP error ${response.status}: ${errorData.message}`);
    }

    return response;
  } catch (error) {
    console.error('Error updating news item:', error);
    throw error;
  }
};


// Export all the functions 
const employeeService = {
  createNews,
  getAllNews,
  updateNews,
  deleteNews
}
export default employeeService; 