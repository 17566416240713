import React from 'react';

const MscAdmission = () => {
  return (
    <div className="admission-requirements">
      <h3>Admission Criteria for MSc at Admas University</h3>

      <section>
        <h4>1. Master of Business Administration (MBA)</h4>
        <p>To be admitted to the MBA program, applicants must fulfill the following criteria:</p>
        <ul>
          <li>Minimum first degree in Management or related fields from accredited universities.</li>
          <li>Graduates from foreign universities must obtain degree equivalence from the Education and Training Authority (ETA) of Ethiopia.</li>
          <li>Pass the National Graduate Admission Test (NGAT) administered by the Central Authority (MoE/ETA).</li>
          <li>
            <strong>N.B.</strong> Applicants who score above the minimum standard on the Entrance Exam (as determined by MoE/ETA) will be considered for admission.
          </li>
        </ul>
      </section>

      <section>
        <h4>2. Master of Science in Accounting and Finance (AcFn)</h4>
        <p>Admission requirements for the MSc in Accounting and Finance are as follows:</p>
        <ul>
          <li>First degree in Accounting, Accounting and Finance, Banking and Finance, Banking and Insurance, Cooperative Accounting, Business Education (specializing in Accounting or Banking or Insurance), Business Management, or related fields from accredited universities.</li>
          <li>Master of Business Administration (MBA), ACCA, CPA, or CIMA from accredited institutions.</li>
          <li>Graduates from foreign universities must obtain degree equivalence from the Education and Training Authority (ETA) of Ethiopia.</li>
          <li>Pass the National Graduate Admission Test (NGAT) administered by the Central Authority (MoE/ETA).</li>
          <li>
            <strong>N.B.</strong> Applicants who score above the minimum standard on the Entrance Exam (as determined by MoE/ETA) will be considered for admission.
          </li>
        </ul>
      </section>

      <section>
        <h4>3. Project Management (PM)</h4>
        <p>Applicants for the Project Management program must meet the following criteria:</p>
        <ul>
          <li>Minimum first degree in Management or related fields from accredited universities.</li>
          <li>Graduates from foreign universities must obtain degree equivalence from the Education and Training Authority (ETA) of Ethiopia.</li>
          <li>Pass the entrance examination administered by the university, including both aptitude and subject-related exams.</li>
          <li>Pass the National Graduate Admission Test (NGAT) administered by the Central Authority (MoE/ETA).</li>
          <li>
            <strong>N.B.</strong> Applicants who score above the minimum standard on the Entrance Exam (as determined by MoE/ETA) will be considered for admission.
          </li>
        </ul>
      </section>

      <section>
        <h4>4. Master of Science in Computer Science (CS)</h4>
        <p>Admission requirements for the MSc in Computer Science program include:</p>
        <ul>
          <li>Bachelor's degree in Computer Science, Information Systems, Computer Engineering, Information Science, Information Technology, Software Engineering, or related fields.</li>
          <li>Candidates with a bachelor’s degree in Mathematics, Statistics, Electrical Engineering, or Management Information Systems may be admitted, subject to completing the following bridge courses:</li>
          <ul>
            <li>Fundamentals of Programming</li>
            <li>Data Communication and Networking</li>
            <li>Data Structures and Algorithms</li>
            <li>Database Management Systems</li>
            <li>Fundamentals of Software Engineering</li>
          </ul>
          <li>Graduates from foreign universities must obtain degree equivalence from the Education and Training Authority (ETA) of Ethiopia.</li>
          <li>Pass the National Graduate Admission Test (NGAT) administered by the Central Authority (MoE/ETA).</li>
          <li>
            <strong>N.B.</strong> Applicants who score above the minimum standard on the Entrance Exam (as determined by MoE/ETA) will be considered for admission.
          </li>
        </ul>
      </section>
    </div>
  );
};

export default MscAdmission;