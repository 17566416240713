import React from 'react'
import campus from "../../assets/images/resource/project-10.jpg" ;
import office from '../../assets/images/background/office.jpg';
import photo from "../../assets/images/president.png";
import mekanisa2 from '../../assets/images/icons/mekanisa2.jpg';
import Dean from '../../assets/images/icons/teklay.jpg'
import MekeleStudent from  '../../assets/images/icons/mekelestudent.jpg'
const AdminOffice = () => {
  return (
    <div>
        
  
    <div className="banner-slide">
          <img src={office} alt="Banner 2" className="banner-image" />
          <div className="banner-content">
          <h1>Transforming Lives</h1>
            {/* <div className="text"> */}
              We believe in providing top quality workmanship and are <br />
              so confident in our level of service that we back it up <br /> with a good quality.
            {/* </div> */}
          </div>
        </div>

    <section class="projects-details">
      <h3 style={{textAlign:'center', marginBottom:'30px'}}>Office of the Vice President for Administration and Finance</h3>
        <div class="auto-container">
            <div class="text-block">
                <div class="row">
                    <div class="col-lg-10">
                        <h4>  Elevating Admas University: A Leader in Ethiopian Higher Education</h4>
                        <div class="text">
                     
                       <p> The Office of the Vice President for Administration and Finance at Admas University is dedicated to fostering a robust and sustainable financial environment that supports our mission to be a premier private university in Ethiopia. Our strategic financial management ensures that we allocate resources effectively, enhancing academic programs and student services.    </p>
                                                   
                        </div>
                    </div>
                   
                </div>
               </div>
            <div class="text-block">
                <div class="row">
                    <div class="col-lg-6">
                    <div className="sec-title style-two">
              <h4>Meet Vice President for Administration and Finance</h4>
            </div>
            <div>
              <p>
              Short biography highlighting the President’s background, academic qualifications, and leadership experience. Mention any significant achievements and contributions to the university and community.
              </p>
            </div>
        
                    </div>
                    <div class="col-lg-3">
                        <div class="image"><img src={photo} alt=""/></div>
                        <p className='designation' >Molla Tsegay (PhD)</p>

                    </div>
                </div>
            </div>
           <hr/>
            <div class="text-block-two">
                <div class="row">
                    <div class="col-lg-12">
                        <h4>Key Responsibilities:</h4>
                        <div class="text">
                        The Office of the President is dedicated to several key initiatives that enhance our university's impact:                        </div>
                     <ul>
                      <li><strong> Financial Oversight: </strong> Implementing efficient budgetary practices to maximize funding for academic excellence.</li>
                      <li><strong>Resource Management:</strong>  Ensuring optimal allocation of resources to support innovative teaching and research.</li>
                      <li><strong>Operational Efficiency: </strong> Streamlining administrative processes to enhance overall university performance.</li>
                     </ul>
                    </div>
                 
                </div>
            </div>

            <hr/>
            <div class="text-block-two">
                <div class="row">
                    <div class="col-lg-10">
                        <h4>Commitment to Excellence</h4>
                        <div class="text">
                   <p>
                   Our commitment to transparency and accountability positions Admas University as a trusted institution in Ethiopia's educational landscape. By prioritizing financial integrity, we aim to continually improve our rankings and provide exceptional value to our students.
                   </p>
                    </div>
                 
                </div>
            </div>
        </div>

        <hr/>
        <div class="text-block-two">
                <div class="row">
                    <div class="col-lg-10">
                        <h4>Contact Us</h4>
                        <div class="text">
   <p>
   Explore how the Office of the Vice President for Administration and Finance is shaping the future of Admas University, making it a leading choice for students seeking quality private education in Ethiopia.
   </p>
   For more information or inquiries, please reach out to the Office of the President: 
                        Email: [president@admasuniversity.edu.et]
Phone: [+251 123 456 789]
Office Location: [Building Name, Room Number, Admas University]
   </div>
                 
                 </div>
             </div>
         </div>

     
      
</div>

    </section>
    </div>
  )
}

export default AdminOffice