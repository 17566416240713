import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js/auto';
import mapboxgl from 'mapbox-gl';
import AdminMenu from '../components/Admin/AdminMenu/AdminMenu'; // Assuming you have an AdminMenu component

Chart.register(...registerables);

const VisitorDashboard = () => {
  const [visitorData, setVisitorData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Visitors',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  });
  const [visitorMap, setVisitorMap] = useState(null);

  useEffect(() => {
    // Fetch visitor data from the backend API
    const fetchVisitorData = async () => {
      try {
        const response = await fetch('/api/visits');
        const data = await response.json();

        // Prepare the data for the chart
        const labels = data.map((visit) => `${visit.location.country}, ${visit.location.city}`);
        const visitorData = data.map((visit) => 1);

        setVisitorData({
          labels,
          datasets: [
            {
              label: 'Visitors',
              data: visitorData,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
          ],
        });

        // Initialize the Mapbox map
        initMap(data);
      } catch (error) {
        console.error('Error fetching visitor data:', error);
      }
    };

    fetchVisitorData();
  }, []);

  const initMap = (data) => {
    mapboxgl.accessToken = 'YOUR_MAPBOX_ACCESS_TOKEN';
    const map = new mapboxgl.Map({
      container: 'visitor-map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: 2,
    });

    data.forEach((visit) => {
      new mapboxgl.Marker()
        .setLngLat([visit.location.longitude, visit.location.latitude])
        .addTo(map);
    });

    setVisitorMap(map);
  };

  return (
    <div className="container-fluid admin-pages">
      <div className="row">
        <div className="col-md-3 admin-left-side">
          <AdminMenu />
        </div>
        <div className="col-md-9 admin-right-side">
          <h2>Visitor Dashboard</h2>
          <div id="visitor-map" style={{ height: '400px' }} />
          <Bar data={visitorData} />
        </div>
      </div>
    </div>
  );
};

export default VisitorDashboard;