import React from 'react';
import '../../assets/css/style.css'; // Optional: Import a CSS file for styling
import CTASection from './CTASection';

const MissionVision = () => {
  return (
    <section>
    <section className="mission-vision">
      <div className="container">
     
     
        <h2>Our Mission & Vision</h2>
        <div className="vision">
          <h3>Vision</h3>
          <p>
          Admas University envisions to become “one of the leading private higher education institutions in terms of Technology supported Quality Education and Training, Research, Community Engagement in East Africa by 2035”.          </p>
        </div>
        <div className="mission">
          <h3>Mission</h3>
          <h4>The Missions of Admas University are:</h4>
          <ul class="checklist">
  <li>Provide technology-supported quality higher education at all levels for an affordable price through regular, continuing, and distance education modes to produce competent professionals who can support the development endeavors of the country.</li>
  <li>Conduct quality and outcome-based training to produce middle-level human resources and supply them to the industry.</li>
  <li>Undertake research that helps to solve the economic and social problems of the country and adds new human and material values to society.</li>
  <li>Produce competent entrepreneurs who could contribute to the technology transfer endeavors of the country.</li>
  <li>Render consultancy and short-term training services to businesses, government, and non-government organizations to help them accomplish their objectives competently.</li>
  <li>Engage in University-Industry linkages and community services to fulfill social responsibilities expected of it as an academic institution.</li>
</ul>

       </div>

       
        </div>
        </section>
        <p className="container">

<h2>Goals</h2>
<p>In order to meet and exceed the quality and standard requirements of students, trainees, and stakeholders, the goals of AU are to:</p>
<ol >
  <li> Create a competent, motivated, adaptable, and innovative workforce for the country, which can contribute to poverty reduction and social and economic development by facilitating demand-driven, high-quality technical and vocational education and training, relevant to all sectors of the economy.</li>
  <li> Promote the entrepreneurial development plans of the country through the TVET programmes.</li>
  <li> Offer research-led diversified programmes that are relevant to the country.</li>
  <li> Prepare trainees with the necessary theoretical knowledge, skills, and attitudes so that they can fit for the purpose.</li>
  <li> Undertake research on local, national, and international issues and problems.</li>
  <li> Create national and international links.</li>
</ol>
</p>

<CTASection/>
    </section>
  );
};

export default MissionVision;