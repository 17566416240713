// import React from 'react';
// import ServiceBlock from './ServiceBlock';
// import mainicon from '../../assets/images/icons/mainicon.png'
// import mekele from '../../assets/images/icons/mekele.jpg'
// import mekanissa from '../../assets/images/icons/mekanisa.jpg'
// import Megenagna from '../../assets/images/icons/meg1.jpg';
// import Bish from '../../assets/images/icons/bish.jpg'
// import Kality from '../../assets/images/icons/kality.jpg';
// import distance from '../../assets/images/icons/distance.jpg'
// import meskel from '../../assets/images/icons/meskel.jpg'


// const ServicesSection = (props) => {
//   return (
//     <section className="services-section">
//       <div className="auto-container">
//         <div className="sec-title style-two" style={{textAlign:'center'}}>
//           <h2>Admas Campuses</h2>
//           <div className="text">
//           With the objective of expanding its quality services, the University opened ten (10) colleges/campuses/faculties so far. Eight of these campuses are found in Ethiopia among which six are in Addis Ababa while the rest two are in Bishoftu and Mekelle towns. The other two campuses are located out of Ethiopia_ in the Capitals of Somaliland and Puntland (i.e. Hargeissa and Garowe, respectively). Apart from the regular mode, the University also has a Distance Education College with more than 50 (fifty) coordination offices throughout the country.    </div>
//         </div>
//         <div className="row">
//           {/* Render the individual service blocks here */}
//                     <ServiceBlock
//                       title="Admas University Main Campus"
//                       serviceName=" Main Campus"
//                       name="mainOlympia"
//                       icon={mainicon}
//                     />

//           <ServiceBlock
//                       title="Admas University Megenagna Campus"
//                       serviceName=" Megenagna Campus"
//                       name="megenagna"
//                       icon={Megenagna}
//                     />


//           <ServiceBlock
//                       title="Admas University  Mekanissa Campus"
//                       serviceName=" Mekanissa Campus"
//                       name="mekanisa"
//                       icon={mekanissa}
//                     />
//                       <ServiceBlock
//                       title="Admas University Kality Campus"
//                       serviceName=" Kality Campus"
//                       name="kality"
//                       icon={Kality}
//                     />
//                          <ServiceBlock
//             title="Admas University Main Campus"
//             serviceName=" Mekele Campus"
//             name="mekele"
//             icon={mekele}
//             history={props.history}
//           />

// <ServiceBlock
//             title="Admas University Bisheftu Campus"
//             serviceName=" Bisheftu Campus"
//             name="bisheftu"
//             icon={Bish}
//           />


// <ServiceBlock
//             title="Admas University  Olompya Campus"
//             serviceName=" Misrak Campus"
//             name="olympia"
//             icon={mainicon}
//           />
//             <ServiceBlock
//             title="Admas University Meskel Campus"
//             serviceName=" Meskel Campus"
//             name="meskel"
//             icon={meskel}
//           />
//            <ServiceBlock
//             title="Admas University Distance Studies"
//             serviceName=" Distance Studies"
//             name="distance"
//             icon={distance}
//           />
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ServicesSection;


import React from 'react';
import ServiceBlock from './ServiceBlock';
import mainicon from '../../assets/images/icons/mainicon.png';
import mekele from '../../assets/images/icons/mekele.jpg';
import mekanissa from '../../assets/images/icons/mekanisa.jpg';
import Megenagna from '../../assets/images/icons/meg1.jpg';
import Bish from '../../assets/images/icons/bish.jpg';
import Kality from '../../assets/images/icons/kality.jpg';
import distance from '../../assets/images/icons/distance.jpg';
import meskel from '../../assets/images/icons/meskel.jpg';
import './ServiceSection.css'
const ServicesSection = (props) => {
  return (
    <section className="services-section">
      <div className="auto-container">
        <div className="sec-title style-two" style={{ textAlign: 'center' }}>
          <h2>Admas Campuses</h2>
          <div className="text">
            With the objective of expanding its quality services, the University opened ten (10) colleges/campuses/faculties so far. Eight of these campuses are found in Ethiopia among which six are in Addis Ababa while the rest two are in Bishoftu and Mekelle towns. The other two campuses are located out of Ethiopia in the Capitals of Somaliland and Puntland (i.e. Hargeissa and Garowe, respectively). Apart from the regular mode, the University also has a Distance Education College with more than 50 (fifty) coordination offices throughout the country.
          </div>
        </div>
        <div className="row">
          {/* Render the individual service blocks here */}
          <ServiceBlock
            title="Admas University Main Campus"
            serviceName="Main Campus"
            name="mainOlympia"
            icon={mainicon}
          />
          <ServiceBlock
            title="Admas University Megenagna Campus"
            serviceName="Megenagna Campus"
            name="megenagna"
            icon={Megenagna}
          />
          <ServiceBlock
            title="Admas University Mekanissa Campus"
            serviceName="Mekanissa Campus"
            name="mekanisa"
            icon={mekanissa}
          />
          <ServiceBlock
            title="Admas University Kality Campus"
            serviceName="Kality Campus"
            name="kality"
            icon={Kality}
          />
          <ServiceBlock
            title="Admas University Mekele Campus"
            serviceName="Mekele Campus"
            name="mekele"
            icon={mekele}
          />
          <ServiceBlock
            title="Admas University Bisheftu Campus"
            serviceName="Bisheftu Campus"
            name="bisheftu"
            icon={Bish}
          />
          <ServiceBlock
            title="Admas University Olympiya Campus"
            serviceName="Misrak Campus"
            name="olympia"
            icon={mainicon}
          />
          <ServiceBlock
            title="Admas University Meskel Campus"
            serviceName="Meskel Campus"
            name="meskel"
            icon={meskel}
          />
          <ServiceBlock
            title="Admas University Distance Studies"
            serviceName="Distance Studies"
            name="distance"
            icon={distance}
          />
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;