import React, { useState } from 'react';
import campus from "../../../assets/images/resource/project-10.jpg";
import office from '../../../assets/images/background/office.jpg';
import photo from "../../../assets/images/president.png";
import mekanisa2 from '../../../assets/images/icons/mekanisa2.jpg';
import Dean from '../../../assets/images/icons/teklay.jpg';
import MekeleStudent from  '../../../assets/images/icons/mekelestudent.jpg';
import AdmissionCriteria from './AdmissionCriteria';
import MscAdmission from './MscAdmission';
import DistanceCriteria from './DistanceCriteria';
import { previousDay } from 'date-fns';
import CTASection from '../CTASection';

const Overview = () => {
  const [showAdmissionCriteria, setShowAdmissionCriteria] = useState(false);
  const [showMscAdmissionCriteria, setShowMscAdmissionCriteria] = useState(false)
  const [showDistanceAdmissionCriteria, setDistanceAdmissionCriteria] = useState(false)

  const toggleAdmissionCriteria = () => {
    setShowAdmissionCriteria(prevState => !prevState);
  };

  const toggleMscAdmissionCriteria = () => {
    setShowMscAdmissionCriteria(prevState => !prevState);
  };

  const toggleDistanceAdmissionCriteria = () => {
    setDistanceAdmissionCriteria(prevState => !prevState);
  }

  return (
    <div>
      <div className="banner-slide">
        <img src={office} alt="Banner 2" className="banner-image" />
        <div className="banner-content">
          <h1>Admission</h1>
          We believe in providing top quality workmanship and are <br />
          so confident in our level of service that we back it up <br /> with a good quality.
        </div>
      </div>

      <section className="projects-details">
        <h4 style={{ marginBottom: '30px', marginLeft:'20px' }}>Overview</h4>
        <div className="auto-container">
          <div className="text-block">
            <div className="row">
              <div className="col-lg-10">
                <div className="text">
                  <p>
                    The Admissions Office at Admas University is dedicated to guiding prospective students through a seamless application process. Our mission is to provide high-quality education and foster academic excellence, empowering students to achieve their educational goals in a supportive environment.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="text-block">
            <div className="row">
              <div className="col-lg-10">
                <div className="sec-title style-two">
                  <h4>Types of Admissions</h4>
                </div>
                <div>
                  <p>Undergraduate Admissions: Information for first-time college students.
                  <button onClick={toggleAdmissionCriteria} style={{color:'#31a8db'}}>
                    {showAdmissionCriteria ? 'Hide Admission Criteria' : 'Show Admission Criteria >>'}
                  </button>
                  {showAdmissionCriteria && <AdmissionCriteria />}

                  </p>
                  <p>Graduate Admissions: Details for master's  programs.
                  <button onClick={toggleMscAdmissionCriteria} style={{color:'#31a8db'}}>
                    {showMscAdmissionCriteria ? '<< Hide Admission Criteria' : 'Show Admission Criteria >>'}
                  </button>
                  {showMscAdmissionCriteria && <MscAdmission />}

                  </p>
                  <p>Distance Education Admissions: Details for Distance Learning programs.
                  <button onClick={toggleDistanceAdmissionCriteria} style={{color:'#31a8db'}}>
                    {showDistanceAdmissionCriteria ? '<< Hide Admission Criteria' : 'Show Admission Criteria >>'}
                  </button>
                  {showDistanceAdmissionCriteria && <DistanceCriteria />}

                  </p>
                  <p>TVET: Specific requirements for TVET applicants.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CTASection/>
    </div>
  );
};

export default Overview;