import React, { useState } from 'react';
import photo from "../../assets/images/background/zelalem.png";

const PresidentMessage = () => {
  const [showMore, setShowMore] = useState(false);

  const fullMessage = `
    Dear Students, Faculty, and Esteemed Partners,
    It is with great pride and enthusiasm that I welcome you to Admas University, an institution dedicated to excellence, innovation, and transformative education. Our commitment to academic distinction and research-driven learning has positioned us as a leading force in higher education, shaping future leaders who will drive progress and development in Somaliland and beyond.
    At Admas University, we believe in empowering our students with cutting-edge knowledge, real-world skills, and a global perspective. Through state-of-the-art technology, strong industry partnerships, and a student-centered approach, we are committed to fostering a dynamic learning environment that prepares graduates for success in an ever-evolving world.
    Our university stands as a pillar of progress, playing a crucial role in the educational and socio-economic transformation of our society. We take pride in our achievements and extend heartfelt gratitude to our dedicated faculty, hardworking students, alumni, and all stakeholders who continue to support our mission.
    Together, we will continue to push boundaries, embrace innovation, and uphold the values that define Admas University. I invite you to join us on this journey of excellence and discovery.
    Sincerely,
    Molla Tsegay (PhD)
    President, Admas University
  `;

  const displayedMessage = showMore
    ? fullMessage
    : fullMessage.split('\n').slice(0, 3).join('\n') + '...'; // Show first 5 lines

  return (
    <>
      <section className="about-section-president">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-7">
              <div className="content">
                <div className="sec-title">
                  <h4>Message from the President of <br /> Admas University</h4>
                </div>
                <div className="text">
                  {displayedMessage.split('\n').map((line, index) => (
                    <p key={index}>{line.trim() || <br />}</p>
                  ))}
                  <button
                    style={{ backgroundColor: '#31a8db', color: 'whitesmoke' }}
                    onClick={() => setShowMore(!showMore)}
                    className="btn-more"
                  >
                    {showMore ? '<< Show Less' : 'Show More >>'}
                  </button>
                </div>
              </div>
            </div>
            <div className='col-lg-1'></div>
            <div className="col-lg-4">
              <figure className="image pht">
                <img src={photo} alt="President Molla Tsegay" />
                <figcaption className='designation'>Molla Tsegay (PhD)</figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PresidentMessage;