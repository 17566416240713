import React from 'react'
// import campus from "../../../assets/images/resource/project-10.jpg" ;
import megenagna from '../../../assets/images/background/megnagna.jpg'

import mekanisa2 from '../../../assets/images/icons/mekanisa2.jpg';
import Dean from '../../../assets/images/icons/teklay.jpg'
import MekeleStudent from  '../../../assets/images/icons/mekelestudent.jpg'
const Megenagna = () => {
  return (

<div>
    <div class="banner-slide">
        <img src={megenagna} alt="Transforming Lives at Our Private University" class="banner-image" />
        <div class="banner-content">
            <h1>Transforming Lives at Our Private University</h1>
            <div>
                At our private university, we are dedicated to providing exceptional quality in education and student support. We believe that every student deserves a transformative experience, and we back our commitment with robust resources and personalized services. Join us to achieve your academic and personal goals in a nurturing environment that prioritizes excellence.
            </div>
        </div>
    </div>

    {/* <section class="projects-details"> */}
    <section>
        <div class="auto-container">
            <div class="sec-title style-two">
                <h2 style={{marginTop:'30px'}}>Admas University Megenagna Campus</h2>
            </div>
            <div class="text-block">
                <div class="row">
                    <div class="col-lg-8">
                        <h4>Message from Campus Dean</h4>
         
<div>
<br/>
<br/>
Dear Students,
</div>
<div>
<br/>
<br/>
Welcome to Megenagna Campus! We are committed to fostering an environment that prioritizes your academic success and personal growth. 
Our dedicated faculty and staff are here to support you every step of the way.
</div>
<div>
At our campus, you will find a vibrant community that encourages collaboration, innovation, and inclusivity. 
</div>
<div>
I urge you to take advantage of the resources and opportunities available to you.

Together, let’s make your journey at Megenagna Campus a transformative experience.
</div>
<br/>
Warm regards,



                    </div>
                    <div> </div>
                    <div class="col-lg-3">
                    <figure class="image pht">
    <img src={Dean} alt="" />
    <figcaption>Molla Tsegay (PhD)</figcaption>
  </figure>
                   </div>
                </div>
            </div>

            <div class="text-block" style={{marginTop:'70px'}}>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="sec-title style-two">
                            <h2>Accredited Programs</h2>
                        </div>
                        <h5 class="programs">Master's Degree</h5>
                        <ul class="list">
                            <li><div class="icon-box"><span class="flaticon-book"></span> Accounting & Finance</div></li>
                            <li><div class="icon-box"><span class="flaticon-book"></span> Computer Science</div></li>
                            <li><div class="icon-box"><span class="flaticon-book"></span> Master of Business Administration</div></li>
                            <li><div class="icon-box"><span class="flaticon-book"></span> Project Management</div></li>
                        </ul>
                        <h5 class="programs">Bachelor's Degree</h5>
                        <ul class="list">
                            <li><div class="icon-box"><span class="flaticon-book"></span> Accounting & Finance</div></li>
                            <li><div class="icon-box"><span class="flaticon-book"></span> Business Management</div></li>
                            <li><div class="icon-box"><span class="flaticon-book"></span> Marketing Management</div></li>
                            <li><div class="icon-box"><span class="flaticon-book"></span> Computer Science</div></li>
                            <li><div class="icon-box"><span class="flaticon-book"></span> Hotel Management</div></li>
                        </ul>
                        <div class="programs">TVET</div>
                        <ul class="list">
                            <li><div class="icon-box"><span class="flaticon-book"></span> Hardware & Networking Servicing Level 1-4</div></li>
                            <li><div class="icon-box"><span class="flaticon-book"></span> Marketing & Sales Management Level 2-4</div></li>
                            <li><div class="icon-box"><span class="flaticon-book"></span> Accounting & Finance Level 2-4</div></li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <div class="image"><img src={mekanisa2} alt="Campus Overview" /></div>
                    </div>
                </div>
            </div>

            <div class="text-block-two">
                <div class="row">
                    <div class="col-lg-6">
                        <h4>Student Life @ Megenagna Campus</h4>
                        <p> 
                          At Megenagna Campus, student life is vibrant and engaging. Our diverse student body fosters a sense of community, where friendships are built and experiences shared. 
                        </p>
                        <p>
                          Students can participate in various clubs and organizations, from sports to cultural activities, enriching their university experience. We also provide support services, including academic advising and counseling, to ensure every student feels at home.
                        </p>
                        <p>
                          Join us for events, workshops, and recreational activities that make campus life enjoyable and fulfilling!
                        </p>

                    </div>
                    <div class="col-lg-6">
                        <div class="image"><img src={MekeleStudent} alt="Student Life" /></div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

  )
}

export default Megenagna