import React from 'react';
import image1 from "../../assets/images/background/ad.jpg"
import image2 from "../../assets/images/resource/image-1.jpg"
const AboutSection = () => {
  return (
    <section className="about-section" style={{marginTop:'150px'}}>
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-5">
            <div className="image-box">
              <img src={image1} alt="" />
              {/* <img src={image1} alt="" /> */}
              <div className="year-experience" data-parallax='{"y": 30}'>
                <strong>25</strong> years <br /> Experience
              </div>
            </div>
          </div>
          <div className="col-lg-7 pl-lg-5">
            <div className="sec-title">
              <h5 style={{textAlign:'center'}}>Welcome <span className='social-icon'>@</span> Admas University</h5>
              <h3>Leading Private University in Ethiopia with 25 Years of Excellence</h3>
              <div className="text">
             
              <p>
            Admas University began as a training center, offering specialized short-term programs 
            to address industry demands. With a commitment to excellence, it expanded its 
            academic offerings and evolved into a college, later earning full university status. 
        </p>
        <p>
            Today, Admas University stands as a premier institution, dedicated to providing 
            quality education, fostering innovation, and empowering students for professional 
            success. Through continuous growth and adaptation, it remains a leader in higher 
            education, shaping the future of learners across various disciplines.
        </p>
    </div>

                 
         
        
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;