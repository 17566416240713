// import React, { useState } from 'react';
// import ModalVideo from 'react-modal-video';
// import 'react-modal-video/css/modal-video.min.css';
// import bgImage from '../../assets/images/background/ad.jpg';

// const VideoSection = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const openModal = () => {
//     setIsOpen(true);
//   };

//   return (
//     <section className="video-section" style={{ position: 'relative', textAlign: 'center' }}>
//       {/* Background image */}
//       <div
//         className="video-thumbnail"
//         style={{
//           backgroundImage: `url(${bgImage})`,
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//           height: '400px',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           position: 'relative',
//         }}
//       >
//         {/* Play button */}
//         <button
//           onClick={openModal}
//           style={{
//             backgroundColor: 'rgba(212, 30, 30, 0.7)',
//             border: 'none',
//             borderRadius: '50%',
//             width: '80px',
//             height: '80px',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             cursor: 'pointer',
//           }}
//         >
       
      
//           <i
//             className="flaticon-play"
//             style={{
//               color: '#fff',
//               fontSize: '32px',
//             }}
//           ></i>
//         </button>
//         <div style={{ marginTop: '20px', marginLeft: '60px',fontSize: '18px', fontWeight: 'bold' ,color:'whitesmoke' }}>
//         Watch intro video <br /> about us
//       </div>
//       </div>

//       {/* Video modal */}
//       <ModalVideo
//         channel="youtube"
//         autoplay
//         isOpen={isOpen}
//         videoId="LX27kp0ykDs" // Ensure this matches the YouTube video ID
//         onClose={() => setIsOpen(false)}
//       />

//       {/* Caption */}
     
//     </section>
//   );
// };

// export default VideoSection;

// import React, { useState, useEffect, useRef } from 'react';
// import ModalVideo from 'react-modal-video';
// import 'react-modal-video/css/modal-video.min.css';
// import bgImage from '../../assets/images/background/ad.jpg';

// const VideoSection = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isInView, setIsInView] = useState(false);
//   const sectionRef = useRef(null);

//   const openModal = () => {
//     setIsOpen(true);
//   };

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         setIsInView(entry.isIntersecting);
//       },
//       { threshold: 0.1 } // Trigger when 10% of the component is visible
//     );

//     if (sectionRef.current) {
//       observer.observe(sectionRef.current);
//     }

//     return () => {
//       if (sectionRef.current) {
//         observer.unobserve(sectionRef.current);
//       }
//     };
//   }, []);

//   return (
//     <section
//       ref={sectionRef}
//       className="video-section"
//       style={{ position: 'relative', textAlign: 'center' }}
//     >
//       {/* Background image */}
//       <div
//         className="video-thumbnail"
//         style={{
//           backgroundImage: `url(${bgImage})`,
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//           height: '400px',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           position: 'relative',
//         }}
//       >
//         {/* Play button */}
//         <button
//           onClick={openModal}
//           style={{
//             backgroundColor: 'rgba(212, 30, 30, 0.7)',
//             border: 'none',
//             borderRadius: '50%',
//             width: '80px',
//             height: '80px',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             cursor: 'pointer',
//           }}
//         >
//           <i
//             className="flaticon-play"
//             style={{
//               color: '#fff',
//               fontSize: '32px',
//             }}
//           ></i>
//         </button>
//         <div style={{ marginTop: '20px', marginLeft: '60px', fontSize: '38px', fontWeight: 'bold', color: 'whitesmoke' }}>
//           Watch intro video <br /> about us
//         </div>
//       </div>

//       {/* Video modal */}
//       <ModalVideo
//         channel="youtube"
//         autoplay={isInView} // Play if in view
//         isOpen={isOpen}
//         videoId="Cmlv1RAeBn0" // Ensure this matches the YouTube video ID
//         onClose={() => {
//           setIsOpen(false);
//           setIsInView(false); // Stop video when modal closes
//         }}
//       />
//     </section>
//   );
// };

// export default VideoSection;


// import React, { useState, useEffect, useRef } from 'react';
// import ModalVideo from 'react-modal-video';
// import 'react-modal-video/css/modal-video.min.css';

// const VideoSection = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isInView, setIsInView] = useState(false);
//   const sectionRef = useRef(null);

//   const openModal = () => {
//     setIsOpen(true);
//   };

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         setIsInView(entry.isIntersecting);
//       },
//       { threshold: 0.1 } // Trigger when 10% of the component is visible
//     );

//     if (sectionRef.current) {
//       observer.observe(sectionRef.current);
//     }

//     return () => {
//       if (sectionRef.current) {
//         observer.unobserve(sectionRef.current);
//       }
//     };
//   }, []);

//   return (
//     <section
//       ref={sectionRef}
//       className="video-section"
//       style={{ position: 'relative', textAlign: 'center' }}
//     >
//       {/* Video thumbnail area */}
//       <div
//         className="video-thumbnail"
//         style={{
//           height: '400px',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           position: 'relative',
//           backgroundColor: 'black', // Optional: add a background color
//         }}
//       >
//         {/* Play button */}
//         <button
//           onClick={openModal}
//           style={{
//             backgroundColor: 'rgba(212, 30, 30, 0.7)',
//             border: 'none',
//             borderRadius: '50%',
//             width: '80px',
//             height: '80px',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             cursor: 'pointer',
//           }}
//         >
//           <i
//             className="flaticon-play"
//             style={{
//               color: '#fff',
//               fontSize: '32px',
//             }}
//           ></i>
//         </button>
//         <div style={{ marginTop: '20px', marginLeft: '60px', fontSize: '38px', fontWeight: 'bold', color: 'whitesmoke' }}>
//           Watch intro video <br /> about us
//         </div>
//       </div>

//       {/* Video modal */}
//       <ModalVideo
//         channel="youtube"
//         autoplay={isInView} // Play if in view
//         isOpen={isOpen}
//         videoId="Cmlv1RAeBn0" // Ensure this matches the YouTube video ID
//         onClose={() => {
//           setIsOpen(false);
//           setIsInView(false); // Stop video when modal closes
//         }}
//       />
//     </section>
//   );
// };

// export default VideoSection;

// import React, { useState, useEffect, useRef } from 'react';
// import ModalVideo from 'react-modal-video';
// import 'react-modal-video/css/modal-video.min.css';

// const VideoSection = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const sectionRef = useRef(null);

//   const openModal = () => {
//     setIsOpen(true);
//   };

//   useEffect(() => {
//     const handleFocus = () => {
//       if (!isOpen) {
//         openModal(); // Open the modal and play the video
//       }
//     };

//     const sectionElement = sectionRef.current;
//     if (sectionElement) {
//       sectionElement.addEventListener('focus', handleFocus);
//       sectionElement.tabIndex = 0; // Make the section focusable
//     }

//     return () => {
//       if (sectionElement) {
//         sectionElement.removeEventListener('focus', handleFocus);
//       }
//     };
//   }, [isOpen]);

//   return (
//     <section
//       ref={sectionRef}
//       className="video-section"
//       style={{ position: 'relative', textAlign: 'center' }}
//     >
//       {/* Video thumbnail area */}
//       <div
//         className="video-thumbnail"
//         style={{
//           height: '400px',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           position: 'relative',
//           backgroundColor: 'black', // Optional: add a background color
//         }}
//       >
//         {/* Play button */}
//         <button
//           onClick={openModal}
//           style={{
//             backgroundColor: 'rgba(212, 30, 30, 0.7)',
//             border: 'none',
//             borderRadius: '50%',
//             width: '80px',
//             height: '80px',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             cursor: 'pointer',
//           }}
//         >
//           <i
//             className="flaticon-play"
//             style={{
//               color: '#fff',
//               fontSize: '32px',
//             }}
//           ></i>
//         </button>
//         <div style={{ marginTop: '20px', marginLeft: '60px', fontSize: '38px', fontWeight: 'bold', color: 'whitesmoke' }}>
//           Watch intro video <br /> about us
//         </div>
//       </div>

//       {/* Video modal */}
//       <ModalVideo
//         channel="youtube"
//         autoplay
//         isOpen={isOpen}
//         videoId="Cmlv1RAeBn0" // Ensure this matches the YouTube video ID
//         onClose={() => {
//           setIsOpen(false);
//         }}
//       />
//     </section>
//   );
// };

// export default VideoSection;


import React, { useState, useEffect, useRef } from 'react';

const VideoSection = () => {
  const [isInView, setIsInView] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.1 } // Trigger when 10% of the component is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className="video-section"
      style={{ position: 'relative', textAlign: 'center' }}
      tabIndex={0} // Make the section focusable
      onFocus={() => {
        // Autoplay video when section gains focus
        if (isInView) {
          document.getElementById('youtube-embed').src += '?autoplay=1';
        }
      }}
    >
      {/* Video thumbnail area */}
      <div
        className="video-thumbnail"
        style={{
          height: '400px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          backgroundColor: 'black', // Optional: add a background color
        }}
      >
        <iframe
          id="youtube-embed"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/Cmlv1RAeBn0"
          frameBorder="0"
          allowFullScreen
          style={{ display: isInView ? 'block' : 'none' }} // Show only when in view
        ></iframe>
        <div style={{ marginTop: '20px', fontSize: '38px', fontWeight: 'bold', color: 'whitesmoke' }}>
          Watch intro video <br /> about us
        </div>
      </div>
    </section>
  );
};

export default VideoSection;