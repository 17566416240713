import React, { useState, useEffect } from 'react';
import eventService from '../../../services/event.service'; // Adjust the path as needed
import AdminMenu from '../Admin/AdminMenu/AdminMenu';

const ListEventForm = () => {
  const [events, setEvents] = useState([]);
  const [token, setToken] = useState('your-access-token'); // Replace this with actual token management
  const [editingEvent, setEditingEvent] = useState(null); // Track the event being edited
  const [editedEvent, setEditedEvent] = useState(null); // Store the edited event data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      setError(null); // Reset error state before fetching
      try {
        const response = await eventService.getAllEvents(token); // Fetch events from the API
        if (response.ok) {
          const res = await response.json();
          setEvents(res.data); // Update state with fetched events
        } else {
          setError('Failed to fetch events.');
        }
      } catch (error) {
        setError('Error fetching events: ' + error.message);
      } finally {
        setLoading(false); // End loading state
      }
    };

    fetchEvents();
  }, [token]);

  const handleEdit = (eventItem) => {
    setEditingEvent(eventItem);
    setEditedEvent({ ...eventItem }); // Clone the event item for editing
  };

  const handleSaveEdit = async () => {
    try {
      const response = await eventService.updateEvent(editedEvent.id, editedEvent, token); // Update event
      if (response.ok) {
        setEvents(
          events.map((item) =>
            item.id === editedEvent.id ? editedEvent : item
          )
        ); // Update state
        setEditingEvent(null);
        setEditedEvent(null);
        console.log('Event updated successfully.');
      } else {
        console.error('Error updating event:', response.status);
      }
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const handleCancel = () => {
    setEditingEvent(null);
    setEditedEvent(null);
  };

  const handleDelete = async (eventItem) => {
    try {
      const response = await eventService.deleteEvent(eventItem.id, token); // Delete event
      if (response.ok) {
        setEvents(events.filter((item) => item.id !== eventItem.id)); // Update state
        console.log('Event deleted successfully.');
      } else {
        console.error('Error deleting event:', response.status);
      }
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  return (
    <>
      <div className="container-fluid admin-pages">
        <div className="row">
          <div className="col-md-3 admin-left-side">
            <AdminMenu />
          </div>
          <div className="col-md-9 admin-right-side">
            <div style={{ minHeight: '100vh', marginTop: '200px' }}>
              <h1>Events</h1>
              {loading ? (
                <p>Loading events...</p> // Loading feedback
              ) : error ? (
                <p className="error">{error}</p> // Error feedback
              ) : (
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Academic Year</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {events.map((eventItem) => (
                        <tr key={eventItem.id}>
                          <td>
                            {editingEvent && editingEvent.id === eventItem.id ? (
                              <input
                                type="text"
                                value={editedEvent.title}
                                onChange={(e) =>
                                  setEditedEvent({ ...editedEvent, title: e.target.value })
                                }
                              />
                            ) : (
                              eventItem.title
                            )}
                          </td>
                          <td>
                            {editingEvent && editingEvent.id === eventItem.id ? (
                              <input
                                type="date"
                                value={editedEvent.date}
                                onChange={(e) =>
                                  setEditedEvent({ ...editedEvent, date: e.target.value })
                                }
                              />
                            ) : (
                              eventItem.date
                            )}
                          </td>
                          <td>
                            {editingEvent && editingEvent.id === eventItem.id ? (
                              <textarea
                                value={editedEvent.description}
                                onChange={(e) =>
                                  setEditedEvent({ ...editedEvent, description: e.target.value })
                                }
                              />
                            ) : (
                              eventItem.description
                            )}
                          </td>
                          <td>
                            {editingEvent && editingEvent.id === eventItem.id ? (
                              <input
                                type="text"
                                value={editedEvent.academicYear}
                                onChange={(e) =>
                                  setEditedEvent({ ...editedEvent, academicYear: e.target.value })
                                }
                              />
                            ) : (
                              eventItem.academicYear
                            )}
                          </td>
                          <td>
                            {editingEvent && editingEvent.id === eventItem.id ? (
                              <div>
                                <button className="btn btn-primary" onClick={handleSaveEdit}>
                                  Save
                                </button>
                                <button className="btn btn-secondary" onClick={handleCancel}>
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button className="btn btn-primary" onClick={() => handleEdit(eventItem)}>
                                  Edit
                                </button>
                                <button className="btn btn-danger" onClick={() => handleDelete(eventItem)}>
                                  Delete
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListEventForm;