import React from 'react';

const DistanceCriteria = () => {
  return (
    <div className="general-admission-criteria">
      <h1>General Admission Criteria</h1>

      <p>
        Applicants for admission to all degree programs must satisfy one of the following criteria:
      </p>

      <ul>
        <li>
          Successful completion of a two-year preparatory program with the minimum University Entrance Examination result set for admission by the Ministry of Education for the entry year.
        </li>
        <li>
          Completion of a Diploma program (12+2 or 10+3, Level 4) with two years of relevant work experience after graduation, certified by the Center of Competency (COC), and passing the University’s entrance examination.
        </li>
        <li>
          A degree program student from other accredited Higher Education Institutions who withdrew for non-academic reasons.
        </li>
        <li>
          A first-degree graduate in any field of study from accredited Higher Education Institutions.
        </li>
      </ul>

      <p>
        Note: These minimum requirements may be scaled up when there is high competition for admission or at the discretion of the University Senate.
      </p>

      <p>
        In cases of high competition for admission, additional factors will be considered, including:
      </p>
      <ul>
        <li>Grade point average at graduation</li>
        <li>Additional training after graduation</li>
        <li>Years of service</li>
        <li>Gender</li>
        <li>Supporting letters from employers</li>
      </ul>
    </div>
  );
};

export default DistanceCriteria;