import React from 'react';

const AdmissionCriteria = () => {
  return (
    <div className="admission-criteria">
      <h3>Admission Criteria for BA/BSc Degree at Admas University</h3>
      <p>
        Applicants for admission to the BA/BSc degree at Admas University (AdU) must satisfy one or more of the following criteria:
      </p>

      <h4>1. After Successful Completion of Grade 12 or Preparatory Program:</h4>
      <ul>
        <li>Meet the cut-off points set by the Ministry of Education.</li>
        <li>Comply with the Senate legislation of the University.</li>
      </ul>

      <h4>2. After Completion of Diploma (10+3 or 12+2) or TVET Level IV in Related Fields:</h4>
      <ul>
        <li>Hold a diploma (10+3 or 12+2) or TVET Level IV certification from a recognized institution.</li>
        <li>Certification by the Center of Competency established by the TVET Regional Bureau of Labor and Skills.</li>
        <li>Possess two years of relevant work experience in the qualification area.</li>
        <li>Pass the entrance examination prepared by the department(s).</li>
      </ul>

      <h4>3. After Having a First Degree:</h4>
      <ul>
        <li>BA, BSc, or higher degree certification from a recognized institution in any profession, supported by an official transcript.</li>
        <li>Meet the admission criteria set by the University.</li>
      </ul>

      <h4>4. For Foreign Students:</h4>
      <ul>
        <li>BA, BSc, or higher degree certification from a recognized institution.</li>
        <li>Certificates equivalence meeting Ethiopian Ministry of Education standards, approved and authenticated by the Ethiopian Transnational Authority (ETA).</li>
      </ul>

      <h4>5. Student Transfer:</h4>
      <ul>
        <li>Transfer from a recognized University in the same field of study.</li>
      </ul>

      <h4>6. In Cases of High Competition for Admission:</h4>
      <ul>
        <li>Grade 12 university entrance examination result and completion year.</li>
        <li>Grade point average at graduation.</li>
        <li>Additional training after graduation.</li>
        <li>Work experience and a supporting letter from the respective employer.</li>
        <li>The entrance examination result will be considered in candidate selection.</li>
      </ul>

      <h4>Minimum Requirements for Admission to All Degree Programs:</h4>
      <ul>
        <li>Successful completion of a two-year preparatory program with the minimum University Entrance Examination results set by the Ministry of Education.</li>
        <li>Completion of a Diploma program (12+2, 10+3, Level IV) with two years of relevant work experience and Center of Competency certification, passing the University’s entrance examination.</li>
        <li>A degree program student from other accredited Higher Education Institutions who withdrew for non-academic reasons.</li>
        <li>A first-degree graduate in any field of study from accredited Higher Education Institutions.</li>
      </ul>

      <p>
        <strong>Note:</strong> Minimum requirements may be scaled up in cases of high competition for admission or as determined by the University Senate.
      </p>
    </div>
  );
};

export default AdmissionCriteria;