import React from 'react';

function AdminMenu(props) {
  return (
    <div>
      <div className="admin-menu" style={{marginTop:'200px'}}>
        <h2 className='admin'>Admin Menu</h2>
      </div>
      <div className="list-group admenu" >
        <a href="/visitor/dashboard" className="list-group-item">Dashboard</a>    
        <a href="/admin/add-news" className="list-group-item">Add News</a>        
        <a href="/admin/list-news" className="list-group-item">News List</a>
        <a href="/admin/add-events" className="list-group-item">Add Events</a>
        <a href="/admin/list-events" className="list-group-item">Events List</a>
        <a href="/admin/add-employee" className="list-group-item">Add employee</a>
        <a href="/admin/employees" className="list-group-item">Employees List</a>
        <a href="/admin/add-announcement" className="list-group-item">Add Blog</a>
        <a href="/admin/list-announcement" className="list-group-item">Blog List</a>
        <a href="/admin/add-vacancy" className="list-group-item">Add Vacancy</a>
      </div>
    </div>
  );
}

export default AdminMenu;