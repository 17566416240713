import React, { useState } from 'react';
import customerService from '../../services/customer.service';
import employeeService from '../../services/employee.service';
import { useAuth } from '../../context/AuthContext';
import ScrollToTopButton from '../pages/ScrollToTopButton';

const Contact = () => {
  const [formName, setFormName] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formSubject, setFormSubject] = useState('');
  const [formMessage, setFormMessage] = useState('');

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');

  let loggedInEmployeeToken = '';
  const { employee } = useAuth();
  if (employee && employee.employee_token) {
    loggedInEmployeeToken = employee.employee_token;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;

    if (!formName) {
      setNameError('Name is required');
      valid = false;
    } else {
      setNameError('');
    }

    if (!formEmail) {
      setEmailError('Email is required');
      valid = false;
    } else {
      setEmailError('');
    }

    if (!formMessage) {
      setMessageError('Message is required');
      valid = false;
    } else {
      setMessageError('');
    }

    if (valid) {
      const emailData = {
        name: formName,
        email: formEmail,
        subject: formSubject,
        message: formMessage,
      };

      customerService.createCustomer(emailData).then((response) => {
        setFormName('');
        setFormEmail('');
        setFormSubject('');
        setFormMessage('');
        alert('Email sent successfully!');
      }).catch((error) => {
        alert('Error sending email. Please try again later.');
      });
    }
  };

  return (
    <>
      <section className="contact-section" style={{marginTop:'150px'}}>
        <div className="auto-container">
          <div className="contact-title">
            <h2>Drop us message</h2>
            
          </div>
          <div className="row clearfix">
            <div className="form-column col-lg-7">
              <div className="inner-column">
                <div className="contact-form">
                  <form onSubmit={handleSubmit}>
                    <div className="row clearfix">
                      <div className="form-group col-md-12">
                        <input
                          type="text"
                          name="form_name"
                          placeholder="Your Name"
                          value={formName}
                          onChange={(e) => setFormName(e.target.value)}
                          required
                        />
                        {nameError && <div className="error">{nameError}</div>}
                      </div>
                      <div className="form-group col-md-12">
                        <input
                          type="email"
                          name="email"
                          placeholder="Your Email"
                          value={formEmail}
                          onChange={(e) => setFormEmail(e.target.value)}
                          required
                        />
                        {emailError && <div className="error">{emailError}</div>}
                      </div>
                      <div className="form-group col-md-12">
                        <input
                          type="text"
                          name="form_subject"
                          placeholder="Subject"
                          value={formSubject}
                          onChange={(e) => setFormSubject(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <textarea
                          name="form_message"
                          placeholder="Message"
                          value={formMessage}
                          onChange={(e) => setFormMessage(e.target.value)}
                          required
                        ></textarea>
                        {messageError && <div className="error">{messageError}</div>}
                      </div>
                      <div className="form-group col-md-12">
                        <input id="form_botcheck" name="form_botcheck" className="form-control" type="hidden" value="" />
                        <button className="theme-btn btn-style-one" type="submit" data-loading-text="Please wait...">
                          <span>Submit now</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="info-column col-lg-5">
              <div className="inner-column">
                <h4>Our Address</h4>
                <div className="text">The main campus is located in the heart of the city,</div>
                <ul>
                  <li><i className="flaticon-pin"></i><span>Address:</span> Dembel,Addis Ababa, Ethiopia</li>
                  <li><i className="flaticon-email"></i><span>email:</span>info@admasuniversity.edu.et</li>
                  <li><i className="flaticon-phone"></i><span>phone:</span> 0115-50-88-08/10 </li>
                  <li><i className="flaticon-phone"></i><span>Fax:</span> 0115-50-89-01 </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="map-section">
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3940.7118491003227!2d38.758499!3d9.0080024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2set!4v1687890000000!5m2!1sen!2set"
            width="100%"
            height="470"
            style={{ border: '0' }}
            allowFullScreen
          ></iframe>
        </div>
      </section>
      <ScrollToTopButton />
    </>
  );
};

export default Contact;