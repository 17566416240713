import React, { useState } from 'react';
// Import employee service
import employeeService from '../../../../services/employee.service';
// Import the useAuth hook
import { useAuth } from '../../../../context/AuthContext';

function AddEmployeeForm(props) {
  const [employee_email, setEmail] = useState('');
  const [employee_first_name, setFirstName] = useState('');
  const [employee_last_name, setLastName] = useState('');
  const [employee_phone, setPhoneNumber] = useState('');
  const [employee_password, setPassword] = useState('');
  const [active_employee, setActive_employee] = useState(1);
  const [company_role_id, setCompany_role_id] = useState(1);
  const [position, setPosition] = useState(''); // New state for position
  const [specialization, setSpecialization] = useState(''); // New state for specialization
  const [image, setImage] = useState(null);

  // Error states
  const [emailError, setEmailError] = useState('');
  const [firstNameRequired, setFirstNameRequired] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [success, setSuccess] = useState(false);
  const [serverError, setServerError] = useState('');

  // Create a variable to hold the user's token
  let loggedInEmployeeToken = '';
  const { employee } = useAuth();
  if (employee && employee.employee_token) {
    loggedInEmployeeToken = employee.employee_token;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true; // Flag

    // Client-side validations
    if (!employee_first_name) {
      setFirstNameRequired('First name is required');
      valid = false;
    } else {
      setFirstNameRequired('');
    }

    if (!employee_email) {
      setEmailError('Email is required');
      valid = false;
    } else if (!employee_email.includes('@')) {
      setEmailError('Invalid email format');
      valid = false;
    } else {
      const regex = /^\S+@\S+\.\S+$/;
      if (!regex.test(employee_email)) {
        setEmailError('Invalid email format');
        valid = false;
      } else {
        setEmailError('');
      }
    }

    if (!employee_password || employee_password.length < 6) {
      setPasswordError('Password must be at least 6 characters long');
      valid = false;
    } else {
      setPasswordError('');
    }

    // If the form is not valid, do not submit 
    if (!valid) {
      return;
    }

    const formData = new FormData();
    formData.append('employee_email', employee_email);
    formData.append('employee_first_name', employee_first_name);
    formData.append('employee_last_name', employee_last_name);
    formData.append('employee_phone', employee_phone);
    formData.append('employee_password', employee_password);
    formData.append('active_employee', active_employee);
    formData.append('company_role_id', company_role_id);
    formData.append('position', position); // Append position
    formData.append('specialization', specialization); // Append specialization

    if (image) {
      formData.append('image', image);
    }

    const newEmployee = employeeService.createEmployee(formData, loggedInEmployeeToken);
    newEmployee
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setServerError(data.error);
        } else {
          setSuccess(true);
          setServerError('');
          setTimeout(() => {
            window.location.href = '/';
          }, 2000);
        }
      })
      .catch((error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        setServerError(resMessage);
      });
  }

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <section className="contact-section">
      <div className="auto-container">
        <div className="contact-title">
          <h2>Add a new employee</h2>
        </div>
        <div className="row clearfix">
          <div className="form-column col-lg-7">
            <div className="inner-column">
              <div className="contact-form">
                <form onSubmit={handleSubmit}>
                  <div className="row clearfix">
                    <div className="form-group col-md-12">
                      {serverError && <div className="validation-error" role="alert">{serverError}</div>}
                      <input type="email" name="employee_email" value={employee_email} onChange={event => setEmail(event.target.value)} placeholder="Employee email" />
                      {emailError && <div className="validation-error" role="alert">{emailError}</div>}
                    </div>
                    <div className="form-group col-md-12">
                      <input type="text" name="employee_first_name" value={employee_first_name} onChange={event => setFirstName(event.target.value)} placeholder="Employee first name" />
                      {firstNameRequired && <div className="validation-error" role="alert">{firstNameRequired}</div>}
                    </div>

                    <div className="form-group col-md-12">
                      <input type="text" name="employee_last_name" value={employee_last_name} onChange={event => setLastName(event.target.value)} placeholder="Employee last name" required />
                    </div>

                    <div className="form-group col-md-12">
                      <input type="text" name="employee_phone" value={employee_phone} onChange={event => setPhoneNumber(event.target.value)} placeholder="Employee phone (555-555-5555)" required />
                    </div>

                    <div className="form-group col-md-12">
                      <select name="employee_role" value={company_role_id} onChange={event => setCompany_role_id(event.target.value)} className="custom-select-box">
                        <option value="1">Employee</option>
                        <option value="2">Manager</option>
                        <option value="3">Admin</option>
                      </select>
                    </div>

                    <div className="form-group col-md-12">
                      <input type="text" name="position" value={position} onChange={event => setPosition(event.target.value)} placeholder="Position" required />
                    </div>

                    <div className="form-group col-md-12">
                      <input type="text" name="specialization" value={specialization} onChange={event => setSpecialization(event.target.value)} placeholder="Specialization" required />
                    </div>

                    <div className="form-group col-md-12">
                      <input type="password" name="employee_password" value={employee_password} onChange={event => setPassword(event.target.value)} placeholder="Employee password" />
                      {passwordError && <div className="validation-error" role="alert">{passwordError}</div>}
                    </div>
                    <div>
                      <label htmlFor="image">Image:</label>
                      <input
                        type="file"
                        id="image"
                        onChange={handleImageChange}
                        required
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <button className="theme-btn btn-style-one" type="submit" data-loading-text="Please wait..."><span>Add employee</span></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddEmployeeForm;