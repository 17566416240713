import React, { useState } from 'react';
import eventService from '../../../services/event.service';
import AdminMenu from '../Admin/AdminMenu/AdminMenu'
const AdminAddEvent = ({ token }) => {
  const [formData, setFormData] = useState({
    title: '',
    date: '',
    description: '',
    academicYear: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await eventService.createEvent(formData, token);
    if (response.ok) {
      alert("Event added successfully!");
      setFormData({ title: '', date: '', description: '', academicYear: '' });
    } else {
      alert("Failed to add event.");
    }
  };

  return (
    <div className="container-fluid admin-pages">
    <div className="row">
      <div className="col-md-3 admin-left-side">
        <AdminMenu />
      </div>
      <div className="col-md-9 admin-right-side">
    <form onSubmit={handleSubmit}>
      <h2>Add Event</h2>
      <input type="text" name="title" placeholder="Event Title" value={formData.title} onChange={handleChange} required />
      <input type="date" name="date" value={formData.date} onChange={handleChange} required />
      <textarea name="description" placeholder="Description" value={formData.description} onChange={handleChange} required />
      <input type="text" name="academicYear" placeholder="Academic Year" value={formData.academicYear} onChange={handleChange} required />
      <button type="submit">Add Event</button>
    </form>
 
  </div>
  </div>
  </div>
   );
};

export default AdminAddEvent;