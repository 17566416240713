import React, { useEffect, useState } from 'react';
import eventService from '../../services/event.service';
import './UserUpcomingEvents.css'; // Ensure this is imported

const UserUpcomingEvents = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const result = await eventService.getUpcomingEvents();
      console.log(result);
      
      if (result.status === "success") {
        setEvents(result.data); // Ensure this is an array of events
      } else {
        setEvents([]); // Handle any error cases appropriately
      }
    };
    fetchEvents();
  }, []);

  return (
    <div className="container">
      <h2>Upcoming Events</h2>
      {events.length > 0 ? (
        <div className="grid">
          {events.map(event => {
            const eventDate = event.date ? new Date(event.date) : null;
            const formattedDate = eventDate && !isNaN(eventDate) 
              ? eventDate.toISOString().slice(0, 10) 
              : 'Invalid Date';

            return (
              <div className="quadrant" key={event.id}>
                <h3>{event.title}</h3>
                <p>Date: {formattedDate}</p>
                <p>{event.description}</p>
              </div>
            );
          })}
        </div>
      ) : (
        <p>No upcoming events.</p>
      )}
    </div>
  );
};

export default UserUpcomingEvents;