// import React from 'react'
// import campus from "../../../assets/images/resource/project-10.jpg" ;
// import image1 from "../../../assets/images/background/ad.jpg"

// import mekanisa2 from '../../../assets/images/icons/mekanisa2.jpg';
// import Dean from '../../../assets/images/icons/teklay.jpg'
// import MekeleStudent from  '../../../assets/images/icons/mekelestudent.jpg'
// import '../../../assets/css/style.css'
// const MainCampus = () => {
//   const [isInView, setIsInView] = useState(false);
//     const sectionRef = useRef(null);
  
//     useEffect(() => {
//       const observer = new IntersectionObserver(
//         ([entry]) => {
//           setIsInView(entry.isIntersecting);
//         },
//         { threshold: 0.1 } // Trigger when 10% of the component is visible
//       );
  
//       if (sectionRef.current) {
//         observer.observe(sectionRef.current);
//       }
//   return (
//     <div>
        
  
//     <div className="banner-slide">
//           <img src={image1} alt="Banner 2" className="banner-image" />
//           <div className="banner-content">
//           <h1>Transforming Lives</h1>
//             {/* <div className="text"> */}
//               We believe in providing top quality workmanship and are <br />
//               so confident in our level of service that we back it up <br /> with a good quality.
//             {/* </div> */}
//           </div>
//         </div>

//     <section class="projects-details">
//         <div class="auto-container">
//             <div class="sec-title style-two">
//                 <h2>Admas University Main Olympia Campus</h2>
//             </div>
//             <div class="text-block">
//                 <div class="row">
//                     <div class="col-lg-6">
//                         <h4>Message from the Dean – Olympia Campus</h4>
//                         <div class="text">
//                        <p> Welcome to Olympia Campus – Excellence in Education, Success in Your Hands!</p>

//                        <p>At Olympia Campus, we take pride in being a premier private university in a prime, 
//                         accessible location, offering world-class education tailored for career success.
//                          Our commitment to academic excellence, innovation, and practical learning sets us apart as a leading institution.</p>                        </div>
//                     </div>
//                     <div class="col-lg-6">
//                     <figure class="image pht">
//     <img src={Dean} alt="" />
//     <figcaption>Molla Tsegay (PhD)</figcaption>
//   </figure>
//                     </div>
//                 </div>
//             </div>
//             <div class="text-block">
//                 <div class="row">
//                     <div class="col-lg-6">
//                     <div className="sec-title style-two">
//               <h2>Accredited Programs</h2>
//             </div>
            
//                     <div className="col-md-6">
         
//                   <h5 className='programs' >Bachler's Degree</h5>
//                   <>
//                     <ul className="sub-list">
//                         <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Accounting & Finance</div></div></li>
//                         <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Business Management</div></div></li>
//                         <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Marketing Managemnt</div></div></li>
//                         <li><div className="icon-box"><div className=""><span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>Computer Science</div></div></li>
//                     </ul>
//                   </>
                  
             
            
//               </div>
//                     </div>
//                     <div class="col-lg-6">
//                         <div class="image"><img src={mekanisa2} alt=""/></div>
//                     </div>
//                 </div>
//             </div>
           
//             {/* <div class="text-block-two">
//                 <div class="row">
//                     <div class="col-lg-6">
//                         <h4>Student Life @ Olympia Campus – A Vibrant Learning Experience!</h4>
//                         <div class="text">
//                         At Olympia Campus, student life goes beyond the classroom! We offer a dynamic, engaging, and career-focused environment where students thrive academically, socially, and professionally.

// ✨ Why Choose Olympia Campus?
// 📍 Prime & Accessible Location – Study in a convenient, well-connected area.
// 🎓 Quality Education – Industry-relevant courses in Accounting & Finance, Business Management, Marketing Management, and Computer Science.
// 🤝 Supportive Community – Connect with expert faculty, mentors, and like-minded peers.
// 🚀 Career-Ready Skills – Hands-on learning, internships, and industry collaborations.
// 🌍 Diverse Student Life – Join clubs, participate in events, and build lifelong networks.

// At Olympia Campus, we shape leaders, innovators, and problem-solvers. Be part of an exciting academic journey where education meets opportunity!                        </div>
                     
//                     </div>
//                     <div class="col-lg-6">
//                         <div class="image"><img src={MekeleStudent}alt=""/></div>
//                     </div>
//                 </div>
//             </div> */}

// {/* <div class="text-block-two">
//     <div class="row">
//         <div class="col-lg-6">
//             <video 
//                 controls 
//                 style={{ width: '100%', height: 'auto' }} 
//                 src="path_to_your_video.mp4" 
//                 alt="Student Life Video"
//             >
//                 Your browser does not support the video tag.
//             </video>
//         </div>
//         <div class="col-lg-6">
//             <h4>Student Life @ Olympia Campus – A Vibrant Learning Experience!</h4>
//             <div class="text">
//                 At Olympia Campus, student life goes beyond the classroom! We offer a dynamic, engaging, and career-focused environment where students thrive academically, socially, and professionally.
//                 <br /><br /> 
                
//                 ✨ Why Choose Olympia Campus?
//                 <ul>
//                     <li>📍 Prime & Accessible Location – Study in a convenient, well-connected area.</li>
//                     <li>🎓 Quality Education – Industry-relevant courses in Accounting & Finance, Business Management, Marketing Management, and Computer Science.</li>
//                     <li>🤝 Supportive Community – Connect with expert faculty, mentors, and like-minded peers.</li>
//                     <li>🚀 Career-Ready Skills – Hands-on learning, internships, and industry collaborations.</li>
//                     <li>🌍 Diverse Student Life – Join clubs, participate in events, and build lifelong networks.</li>
//                 </ul>
                
//                 At Olympia Campus, we shape leaders, innovators, and problem-solvers. Be part of an exciting academic journey where education meets opportunity!
//             </div>
//         </div>
//     </div>
// </div> */}



// <section
//       ref={sectionRef}
//       className="video-section"
//       style={{ position: 'relative', textAlign: 'center' }}
//       tabIndex={0} // Make the section focusable
//       onFocus={() => {
//         // Autoplay video when section gains focus
//         if (isInView) {
//           document.getElementById('youtube-embed').src += '?autoplay=1';
//         }
//       }}
//     >
//       {/* Video thumbnail area */}
//       <div
//         className="video-thumbnail"
//         style={{
//           height: '400px',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           position: 'relative',
//           backgroundColor: 'black', // Optional: add a background color
//         }}
//       >
//         <iframe
//           id="youtube-embed"
//           width="100%"
//           height="100%"
//           src="https://www.youtube.com/embed/Cmlv1RAeBn0"
//           frameBorder="0"
//           allowFullScreen
//           style={{ display: isInView ? 'block' : 'none' }} // Show only when in view
//         ></iframe>
//         <div style={{ marginTop: '20px', fontSize: '38px', fontWeight: 'bold', color: 'whitesmoke' }}>
//           Watch intro video <br /> about us
//         </div>
//       </div>
//     </section>
//         </div>
//     </section>
//     </div>
//   )
// }

// export default MainCampus


import React, { useState, useEffect, useRef } from 'react';
import campus from "../../../assets/images/resource/project-10.jpg";
import image1 from "../../../assets/images/background/ad.jpg";
import mekanisa2 from '../../../assets/images/icons/mekanisa2.jpg';
import Dean from '../../../assets/images/icons/teklay.jpg';
import MekeleStudent from '../../../assets/images/icons/mekelestudent.jpg';
import '../../../assets/css/style.css';

const MainCampus = () => {
  const [isInView, setIsInView] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.1 } // Trigger when 10% of the component is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div>
      <div className="banner-slide">
        <img src={image1} alt="Banner 2" className="banner-image" />
        <div className="banner-content">
          <h1>Transforming Lives</h1>
          We believe in providing top quality workmanship and are <br />
          so confident in our level of service that we back it up <br /> with a good quality.
        </div>
      </div>

      <section className="projects-details">
        <div className="auto-container">
          <div className="sec-title style-two">
            <h2>Admas University Main Olympia Campus</h2>
          </div>
          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <h4>Message from the Dean – Olympia Campus</h4>
                <div className="text">
                  <p>Welcome to Olympia Campus – Excellence in Education, Success in Your Hands!</p>
                  <p>At Olympia Campus, we take pride in being a premier private university in a prime, 
                    accessible location, offering world-class education tailored for career success.
                    Our commitment to academic excellence, innovation, and practical learning sets us apart as a leading institution.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <figure className="image pht">
                  <img src={Dean} alt="Dean" />
                  <figcaption>Molla Tsegay (PhD)</figcaption>
                </figure>
              </div>
            </div>
          </div>

          <div className="text-block">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-title style-two">
                  <h2>Accredited Programs</h2>
                </div>
                <div className="col-md-6">
                  <h5 className='programs'>Bachelor's Degree</h5>
                  <ul className="sub-list">
                    <li>
                      <div className="icon-box">
                        <span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>
                        Accounting & Finance
                      </div>
                    </li>
                    <li>
                      <div className="icon-box">
                        <span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>
                        Business Management
                      </div>
                    </li>
                    <li>
                      <div className="icon-box">
                        <span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>
                        Marketing Management
                      </div>
                    </li>
                    <li>
                      <div className="icon-box">
                        <span className="flaticon-book" style={{marginRight:'20px', color:'#1ab5f1', fontWeight:'bolder', fontSize:'1.5em'}}></span>
                        Computer Science
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image">
                  <img src={mekanisa2} alt="Mekanisa" />
                </div>
              </div>
            </div>
          </div>

          <section
            ref={sectionRef}
            className="video-section"
            style={{ position: 'relative', textAlign: 'center' }}
            tabIndex={0} // Make the section focusable
            onFocus={() => {
              // Autoplay video when section gains focus
              if (isInView) {
                document.getElementById('youtube-embed').src += '?autoplay=1';
              }
            }}
          >
            <div
              className="video-thumbnail"
              style={{
                height: '400px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                backgroundColor: 'black', // Optional: add a background color
              }}
            >
              <iframe
                id="youtube-embed"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/Cmlv1RAeBn0"
                frameBorder="0"
                allowFullScreen
                style={{ display: isInView ? 'block' : 'none' }} // Show only when in view
              ></iframe>
              <div style={{ marginTop: '20px', fontSize: '38px', fontWeight: 'bold', color: 'whitesmoke' }}>
                Watch intro video <br /> about us
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}

export default MainCampus;