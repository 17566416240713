// Import from the env 
const api_url = process.env.REACT_APP_API_URL;

// A function to create a new event
// const createEvent = async (formData, loggedInEmployeeToken) => {
//   const requestOptions = {
//     method: 'POST',
//     headers: {
//       'x-access-token': loggedInEmployeeToken
//     },
//     body: new FormData()
//   };

 
//   requestOptions.body.append('title', formData.title);
//   requestOptions.body.append('date', formData.date);
//   requestOptions.body.append('description', formData.description);
//   requestOptions.body.append('academic_year', formData.academicYear);
//   console.log(requestOptions)
//   const response = await fetch(`${api_url}/api/employee/event`, requestOptions);
  
//   return response;
// };

const createEvent = async (formData, loggedInEmployeeToken) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'x-access-token': loggedInEmployeeToken,
      'Content-Type': 'application/json' // Specify that we're sending JSON
    },
    body: JSON.stringify({
      title: formData.title,
      date: formData.date,
      description: formData.description,
      academic_year: formData.academicYear
    })
  };

  const response = await fetch(`${api_url}/api/employee/event`, requestOptions);
  
  console.log(response);
  return response;
};

// A function to send a GET request to get all upcoming events
const getUpcomingEvents = async () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  };
  const response = await fetch(`${api_url}/api/employee/event`, requestOptions);

  if (!response.ok) {
    throw new Error(`HTTP error ${response.status}`);
  }
  return response.json();
};

// A function to send a GET request to get all events
const getAllEvents = async () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  };
  const response = await fetch(`${api_url}/api/employee/event`, requestOptions);
 
  if (!response.ok) {
    throw new Error(`HTTP error ${response.status}`);
  }
  return response;
};

// A function to send a DELETE request to delete an event
const deleteEvent = async (eventId, token) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    }
  };
  const response = await fetch(`${api_url}/api/employee/event/${eventId}`, requestOptions);
  return response;
};


const updateEvent = async (eventId, formData, token) => {
  // Convert the date to "yyyy-MM-dd" format
  const formattedDate = new Date(formData.date).toISOString().split('T')[0]; // Get only the date part
  const updatedFormData = {
    ...formData,
    date: formattedDate // Replace the date with the formatted date
  };

  console.log('Updating event:', updatedFormData); // Log the updated form data
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    },
    body: JSON.stringify(updatedFormData)
  };
  const response = await fetch(`${api_url}/api/employee/event/${eventId}`, requestOptions);
  return response;
};
// Export all the functions 
const eventService = {
  createEvent,
  getUpcomingEvents,
  getAllEvents,
  deleteEvent,
  updateEvent
}

export default eventService;