// Import from the env 
const api_url = process.env.REACT_APP_API_URL;


const createVacancy = async (formData, loggedInEmployeeToken) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': loggedInEmployeeToken
    },
    body: JSON.stringify(formData)
  };
console.log(formData);
  const response = await fetch(`${api_url}/api/employee/vacancy`, requestOptions);
  return response;
};

// A function to send get request to get all vacancies
const getAllVacancies = async (token) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    }
  };
  const response = await fetch(`${api_url}/api/employee/vacancy`, requestOptions);
  if (!response.ok) {
    throw new Error(`HTTP error ${response.status}`);
  }
console.log(response)
  return response.json();
};



// A function to send delete request to delete an employee
const deleteVacancy = async (vacancyId, token) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    }
  };
  const response = await fetch(`${api_url}/api/employee/vacancy/${vacancyId}`, requestOptions);
  return response;
}

// Export all the functions 
const employeeService = {
  createVacancy,
  getAllVacancies,
  deleteVacancy
}
export default employeeService;