import React from 'react'
import campus from "../../assets/images/resource/project-10.jpg" ;
import office from '../../assets/images/background/office.jpg';
import photo from "../../assets/images/background/zelalem.png";
import mekanisa2 from '../../assets/images/icons/mekanisa2.jpg';
import Dean from '../../assets/images/icons/teklay.jpg'
import MekeleStudent from  '../../assets/images/icons/mekelestudent.jpg'
const CentralQuality = () => {
  return (
    <div>
        
  
    <div className="banner-slide">
          <img src={office} alt="Banner 2" className="banner-image" />
          <div className="banner-content">
          <h1>Transforming Lives</h1>
            {/* <div className="text"> */}
              We believe in providing top quality workmanship and are <br />
              so confident in our level of service that we back it up <br /> with a good quality.
            {/* </div> */}
          </div>
        </div>

    <section class="projects-details">
      <h3 style={{textAlign:'center', marginBottom:'30px'}}>Quality Assurance Affairs</h3>
        <div class="auto-container">
            <div class="text-block">
                <div class="row">
                    <div class="col-lg-10">
                        <h4>   Welcome to the Office of Quality Assurance Affairs</h4>
                        <div class="text">
                     
                       <p>At Admas University, the Office of the Academic Vice President is dedicated to fostering academic excellence and innovation. Our mission is to enhance the educational experience of our students, support faculty development, and promote cutting-edge research.                        </p>
                               
                        </div>
                    </div>
                   
                </div>
               </div>
            <div class="text-block">
                <div class="row">
                    <div class="col-lg-6">
                    <div className="sec-title style-two">
              <h4>Meet the Academic Vice President</h4>
            </div>
            <div>
              <p>
              Dr. Zelalem has been working for more than Twenty-Three years in Public and Private Higher Education Institutions. His terminal degree is in Educational Research and Evaluation from Addis Ababa University; MSc in Educational Science and Technology: Educational Management, Evaluation, and Assessment track from Twente University, The Netherlands; and BSc in Business Education from Addis Ababa University.  Dr. Zelalem has excelled in various Leadership roles: Vice President for Quality Assurance Affairs, Research and Quality Enhancement, Director for Curriculum Standards and Quality Assurance, Associate Registrar, and Higher Education Management, to mention a few.                </p>
            </div>
        
                    </div>
                    <div class="col-lg-3">
                        <div class="image"><img src={photo} alt=""/></div>
                           <p className='designation' >Zelalem Tafere (PhD)</p>
                          <p style={{textAlign:'center'}}> VP, Quality Assurance Affairs, Admas University</p>
                  
                    </div>
                </div>
            </div>
           <hr/>
            <div class="text-block-two">
                <div class="row">
                    <div class="col-lg-12">
                        <h4>Our Vision for Academic Excellence</h4>
                        <div class="text">
                        The Academic Vice President leads initiatives that align with our commitment to providing a high-quality education that prepares students for success in their careers and communities. We strive to cultivate an environment where academic programs are robust, relevant, and responsive to the needs of our students and the marketplace.                     <ul>
                      <h4>Key Responsibilities</h4>
                      <li><strong> Curriculum Development:</strong> Overseeing the continuous improvement of academic programs to ensure they meet industry standards and equip students with essential skills.</li>
                      <li><strong>Faculty Development:</strong> Supporting faculty through professional development opportunities, workshops, and resources to enhance teaching effectiveness and research capabilities.</li>
                      <li><strong>Research Advancement:</strong> Promoting and facilitating research initiatives that contribute to knowledge creation and community engagement.</li>
                      <li><strong>Academic Policies:</strong> Establishing and maintaining academic policies that uphold academic integrity and support student success.</li>
                      <li><strong>Student Support Services:</strong> Enhancing services that aid students academically, including tutoring, advising, and mentorship programs.</li>

                     </ul>
                    </div>
                 
                </div>
            </div>

            <hr/>
            <div class="text-block-two">
                <div class="row">
                    <div class="col-lg-10">
                        <h4>Academic Initiatives and Innovations</h4>
                        <div class="text">
                        1. New Academic Programs
                        <p>
                        We are excited to announce the launch of several new degree programs that reflect current industry trends and student interests. These programs are designed to provide students with hands-on experience and prepare them for in-demand careers.
                        </p>

                        2. Online Learning Opportunities
                        <p>
                            With the rise of digital education, we are expanding our online course offerings to ensure accessibility for all students. Our online programs maintain the same rigor and quality as our traditional classroom experiences.
                        </p>

                        3. Research Collaborations
                        <p>
                            The Office of the Academic Vice President actively collaborates with industry partners and research institutions to foster innovative research projects. These collaborations provide students and faculty with valuable opportunities for real-world application of their studies.
                        </p>
                    </div>
                 <h4>
                    Join Us in Shaping the Future of Education
                 </h4>

                 <p>
                    At Admas University, we believe that education is a lifelong journey. The Office of the Academic Vice President invites you to be part of our community of scholars and innovators. Together, we can create a brighter future for students and society.
                 </p>
                </div>
            </div>
        </div>

        <hr/>
           
            <div class="text-block-two">
                <div class="row">
                    <div class="col-lg-10">
                        <h4>Contact Us</h4>
                        <div class="text">
                        For more information or inquiries, please reach out to the Office of the President: 
                        Email: [president@admasuniversity.edu.et]
Phone: [+251 123 456 789]
Office Location: [Building Name, Room Number, Admas University]
                    </div>
                 
                </div>
            </div>
        </div>
      
</div>
</div>

    </section>
    </div>
  )
}

export default CentralQuality